import React from 'react'
import mobile from '../assets/image/Group 75645.png'
const App = () => {
  return (
    <section className='w-[100vw] pt-[320px]' >
        <img width={"100%"} src={mobile} alt="app" />
      
    </section>
  )
}

export default App
